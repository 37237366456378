<template>
  <div>
    <notiModal
      v-if="isShowNoti"
      :dataDistrict="dataDistrict"
      @btClose="btClose"
    />
    <localModal
      v-if="isShowLocal"
      :dataDistrict="dataDistrict"
      :dataShopLst="dataShopLst"
      @btClose="btCloseLocal"
    />
    <div id="items-direction" style="position: absolute; top: 0"></div>
    <div id="baomoi-content" class="baomoi-content bm-home">
      <img
        style="margin: 0 auto"
        class="bm-banner-img hidden-md hidden-lg"
        src="../../../assets/images/nsut.jpg"
      />
      <img
        style="margin: 0 auto"
        class="bm-banner-img hidden-sm hidden-xs"
        src="../../../assets/images/nsut-d.jpg"
      />
      <div class="container">
        <div class="row">
          <div class="single-post-wrap">
            <div class="single-content">
              <div class="content-wrap">
                <div class="bm-main-content">
                  <div class="bm-nhathuoc">
                    <p class="normal-title" style="font-size: 20px">
                      SIX.am đã có mặt tại hơn 1000 điểm bán NT lớn và uy tín
                      trên cả nước
                    </p>
                    <div class="col-sm-6">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/h42I1kjv-iw"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                      <div class="clearfix"></div>
                      <p class="sub-img" style="margin-top: 10px">
                        Điểm bán SIX.am tại Quảng Ninh
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/0cq0AeID6es"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                      <div class="clearfix"></div>
                      <p class="sub-img" style="margin-top: 10px">
                        Điểm bán SIX.am tại Đà Nẵng
                      </p>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-sm-6">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/mv6YSGPwLxw"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                      <div class="clearfix"></div>
                      <p class="sub-img" style="margin-top: 10px">
                        Điểm bán SIX.am tại Bắc Giang
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/FyYiy7O8W4s"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                      <div class="clearfix"></div>
                      <p class="sub-img" style="margin-top: 10px">
                        Điểm bán SIX.am tại Bắc Ninh
                      </p>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="diemban">
        <div class="container">
          <div class="block-title">Có 3 cách để đặt mua SIX.am dễ dàng</div>
          <p class="db-cach-sale">
            <span>Cách 1</span> Mua SIX.am ngay tại các nhà thuốc gần bạn:
          </p>
          <div
            class="container-local"
            v-for="(item, index) in dataCityAll"
            :key="index"
          >
            <div class="db-area">
              <p>
                <img src="../../../assets/images/vitri.png" />
              </p>
              <div class="db-areaname">
                <p>{{ item.CityArea }}</p>
              </div>
            </div>
            <div class="db-table">
              <div class="db-col">
                <button
                  class="row-box"
                  v-for="(dis, ind) in item.Districts"
                  :key="ind"
                  @click="btShowNoti(dis)"
                >
                  <p>{{ dis.district }}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <p class="db-cach-sale" style="margin-top: 50px">
            <span>Cách 2</span> Mua SIX.am ngay tại đây:
          </p>
          <div class="row">
            <div class="single-post-wrap">
              <div class="single-content">
                <div class="content-wrap">
                  <div class="bm-main-content">
                    <div class="bm-form bm-dathang">
                      <p class="form-title">Đặt mua SIX.am</p>
                      <p class="form-info">
                        - Giá bán: 300.000 đồng/hộp 20 ống
                      </p>
                      <p class="form-info">
                        - Sử dụng 1-2 ống/tối trước khi đi ngủ 30-60 phút
                      </p>
                      <p class="form-info">
                        - Rẻ hơn và miễn phí vận chuyển khi mua theo liệu trình:
                      </p>
                      <ul>
                        <li>
                          Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá
                          300.000 đồng+ phí ship
                        </li>
                        <li>
                          Liệu trình 1 tháng: 2 hộp SIX.am có giá 600.000 đồng
                          (miễn phí ship)
                        </li>
                        <li>
                          Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.120.000 đồng
                          (miễn phí ship)
                        </li>
                      </ul>
                      <div
                        role="form"
                        class="wpcf7"
                        id="wpcf7-f17-o4"
                        lang="en-US"
                        dir="ltr"
                      >
                        <div class="screen-reader-response">
                          <ul></ul>
                        </div>
                        <form
                          action="/#wpcf7-f17-o4"
                          method="post"
                          class="wpcf7-form init"
                          novalidate="novalidate"
                          data-status="init"
                        >
                          <div class="w-100">
                            <span class="wpcf7-form-control-wrap hoten"
                              ><input
                                type="text"
                                name="hoten"
                                value=""
                                size="40"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Họ và tên"
                                v-model="formRegister.FullName"
                            /></span>
                          </div>
                          <div class="w-50 dathang-left itemNumber"    >
                            <span class="wpcf7-form-control-wrap sdt"
                         
                              ><input
                                type="number"
                                name="sdt"
                                value=""
                                min="99999999"
                                max="9999999999"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Số điện thoại"
                                v-model="formRegister.PhoneNumber"
                            /></span>
                          </div>
                          <div class="w-50 itemSolusion" >
                            <span class="wpcf7-form-control-wrap lieutrinh"
                              
                              ><select
                                name="lieutrinh"
                                aria-required="true"
                                aria-invalid="false"
                                @change="onChange($event)"
                                v-model="selecteded"
                              >
                                <option value="" disabled selected>
                                  Chọn liệu trình
                                </option>
                                <option
                                  value="Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 300.000 đồng + phí ship"
                                >
                                  Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có
                                  giá 300.000 đồng + phí ship
                                </option>
                                <option
                                  value="Liệu trình 1 tháng: 2 hộp SIX.am có giá 600.000 đồng (miễn phí ship)"
                                >
                                  Liệu trình 1 tháng: 2 hộp SIX.am có giá
                                  600.000 đồng (miễn phí ship)
                                </option>
                                <option
                                  value=" Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.120.000 đồng (miễn phí ship)"
                                >
                                  Liệu trình 2 tháng: 4 hộp SIX.am có giá
                                  1.120.000 đồng (miễn phí ship)
                                </option>
                              </select></span
                            >
                          </div>
                          <div class="w-100">
                            <span class="wpcf7-form-control-wrap diachi">
                              <textarea
                                name="diachi"
                                cols="40"
                                rows="10"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Địa chỉ nhận hàng"
                                v-model="formRegister.Note"
                              ></textarea>
                            </span>
                          </div>
                          <p
                            v-if="isShowSuccess"
                            class="mt-2"
                            style="color: #fff; margin-bottom: 16px"
                          >
                            {{ contentSuccess }}
                          </p>
                          <p
                            v-if="isShowWarning"
                            class="mt-2"
                            style="color: #fff; margin-bottom: 16px"
                          >
                            {{ contentWarning }}
                          </p>
                          <div @click="createWebRegister" class="btn-summit">
                            <span
                              role="status"
                              aria-hidden="true"
                              v-if="loadding"
                            ></span>
                            Đặt hàng
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import notiModal from "../../Dialog/noti-dialog.vue";
import { CreateWebRegister } from "@/api/place";
import { GetPlaceByProduct } from "@/api/place";
import localModal from "../../Dialog/local-modal.vue";
export default {
  data() {
    return {
      isShowNoti: true,
      dataPlaceAll: [],
      dataCityAll: [
        {
          CityArea: "Hà Nội",
          Districts: [
            {
              district: "Quận Ba Đình",
            },
            {
              district: "Quận Hoàn Kiếm",
            },
            {
              district: "Quận Tây Hồ",
            },
            {
              district: "Quận Long Biên",
            },
            {
              district: "Quận Cầu Giấy",
            },
            {
              district: "Quận Đống Đa",
            },
            {
              district: "Quận Hai Bà Trưng",
            },
            {
              district: "Quận Hoàng Mai",
            },
            {
              district: "Quận Thanh Xuân",
            },
            {
              district: "Quận Hà Đông",
            },
            {
              district: "Quận Nam Từ Liêm",
            },
            {
              district: "Quận Bắc Từ Liêm",
            },
            {
              district: "Huyện Đông Anh",
            },
            {
              district: "Huyện Gia Lâm",
            },
            {
              district: "Huyện Thanh Trì",
            },
            {
              district: "Huyện Mê Linh",
            },
            {
              district: "Huyện Sóc Sơn",
            },
            {
              district: "Thị Xã Sơn Tây",
            },
            {
              district: "Huyện Ba Vì",
            },
            {
              district: "Huyện Phúc Thọ",
            },
            {
              district: "Huyện Đan Phượng",
            },
            {
              district: "Huyện Hoài Đức",
            },
            {
              district: "Huyện Quốc Oai",
            },
            {
              district: "Huyện Thạch Thất",
            },
            {
              district: "Huyện Chương Mỹ",
            },
            {
              district: "Huyện Thanh Oai",
            },
            {
              district: "Huyện Thường Tín",
            },
            {
              district: "Huyện Phú Xuyên",
            },
            {
              district: "Huyện Ứng Hòa",
            },
            {
              district: "Huyện Mỹ Đức",
            },
          ],
        },
        {
          CityArea: "Hồ Chí Minh",
          Districts: [
            {
              district: "Quận 1",
            },
            {
              district: "Quận 2",
            },
            {
              district: "Quận 3",
            },
            {
              district: "Quận 4",
            },
            {
              district: "Quận 5",
            },
            {
              district: "Quận 6",
            },
            {
              district: "Quận 7",
            },
            {
              district: "Quận 8",
            },
            {
              district: "Quận 9",
            },
            {
              district: "Quận 10",
            },
            {
              district: "Quận 11",
            },
            {
              district: "Quận 12",
            },
            {
              district: "Quận Bình Tân",
            },
            {
              district: "Quận Bình Thạnh",
            },
            {
              district: "Quận Gò Vấp",
            },
            {
              district: "Quận Phú Nhuận",
            },
            {
              district: "Quận Tân Bình",
            },
            {
              district: "Quận Tân Phú",
            },
            {
              district: "Quận Thủ Đức",
            },
            {
              district: "Huyện Bình Chánh",
            },
            {
              district: "Huyện Củ Chi",
            },
            {
              district: "Huyện Hóc Môn",
            },
            {
              district: "Huyện Nhà Bè",
            },
          ],
        },
        {
          CityArea: "Miền Bắc",
          Districts: [
            {
              district: "Hà Giang",
            },
            {
              district: "Cao Bằng",
            },
            {
              district: "Bắc Kạn",
            },
            {
              district: "Tuyên Quang",
            },
            {
              district: "Lào Cai",
            },
            {
              district: "Điện Biên",
            },
            {
              district: "Lai Châu",
            },
            {
              district: "Sơn La",
            },
            {
              district: "Yên Bái",
            },
            {
              district: "Hòa Bình",
            },
            {
              district: "Thái Nguyên",
            },
            {
              district: "Lạng Sơn",
            },
            {
              district: "Quảng Ninh",
            },
            {
              district: "Bắc Giang",
            },
            {
              district: "Phú Thọ",
            },
            {
              district: "Vĩnh Phúc",
            },
            {
              district: "Bắc Ninh",
            },
            {
              district: "Hải Dương",
            },
            {
              district: "Hải Phòng",
            },
            {
              district: "Hưng Yên",
            },
            {
              district: "Thái Bình",
            },
            {
              district: "Hà Nam",
            },
            {
              district: "Nam Định",
            },
            {
              district: "Ninh Bình",
            },
          ],
        },
        {
          CityArea: "Miền Trung",
          Districts: [
            {
              district: "Thanh Hóa",
            },
            {
              district: "Nghệ An",
            },
            {
              district: "Hà Tĩnh",
            },
            {
              district: "Quảng Bình",
            },
            {
              district: "Quảng Trị",
            },
            {
              district: "Thừa Thiên Huế",
            },
            {
              district: "Đà Nẵng",
            },
            {
              district: "Quảng Nam",
            },
            {
              district: "Quảng Ngãi",
            },
            {
              district: "Bình Định",
            },
            {
              district: "Phú Yên",
            },
            {
              district: "Khánh Hòa",
            },
            {
              district: "Kon Tum",
            },
            {
              district: "Gia Lai",
            },
            {
              district: "Đắk Lắk",
            },
            {
              district: "Đắk Nông",
            },
            {
              district: "Lâm Đồng",
            },
            {
              district: "Ninh Thuận",
            },
            {
              district: "Bình Thuận",
            },
          ],
        },
        {
          CityArea: "Miền Nam",
          Districts: [
            {
              district: "Bình Phước",
            },
            {
              district: "Tây Ninh",
            },
            {
              district: "Bình Dương",
            },
            {
              district: "Đồng Nai",
            },
            {
              district: "Bà Rịa - Vũng Tàu",
            },
            {
              district: "Long An",
            },
            {
              district: "Tiền Giang",
            },
            {
              district: "Bến Tre",
            },
            {
              district: "Trà Vinh",
            },
            {
              district: "Vĩnh Long",
            },
            {
              district: "Đồng Tháp",
            },
            {
              district: "An Giang",
            },
            {
              district: "Kiên Giang",
            },
            {
              district: "Cần Thơ",
            },
            {
              district: "Hậu Giang",
            },
            {
              district: "Sóc Trăng",
            },
            {
              district: "Bạc Liêu",
            },
            {
              district: "Cà Mau",
            },
          ],
        },
      ],
      isShowLocal: false,
      isShowNoti: false,
      dataDistrict: {},
      dataShopLst: [],
      dataUpdate: [],
      isShowSuccess: false,
      isShowWarning: false,
      contentSuccess:
        "Đăng kí thành công, nhân viên CSKH sẽ sớm liên hệ với quý khách!",
      contentWarning: "Vui lòng điền đủ thông tin!",
      loadding: false,
      selecteded: "",
      disciption: "",
      formRegister: {
        PhoneNumber: "",
        FullName: "",
        Desctiption: "",
        Note: "",
      },
    };
  },
  components: {
    notiModal,
    localModal,
  },
  methods: {
    btCloseLocal() {
      this.isShowLocal = false;
    },
    btShowNoti(data) {
      this.dataDistrict = data;
      this.dataUpdate = this.dataPlaceAll.filter(
        (p) => p.District == data.district
      );
      console.log(this.dataPlaceAll);
      if (this.dataUpdate.length == 0) {
        this.dataUpdate = this.dataPlaceAll.filter(
          (p) => p.City == "Tỉnh" + " " + data.district
        );
      }
      if (this.dataUpdate.length == 0) {
        this.isShowNoti = true;
      } else {
        this.isShowLocal = true;
        this.dataShopLst = this.dataUpdate;
      }
      // this.dataPlaceAll.forEach((element) => {
      //   if (element.City == "Thành phố Hà Nội") {
      //     //console.log(element);
      //   }
      // });
    },
    btClose() {
      this.isShowNoti = false;
    },
    getPlaceByProduct() {
      GetPlaceByProduct({ ProductID: "SIX.am" }).then((result) => {
        this.dataPlaceAll = result.data.PlaceLst.filter(
          (p) => p.Status == 2 || p.Status == 1
        );
      });
      console.log(this.dataPlaceAll.length);
    },
    onChange(event) {
      this.disciption = event.target.value;
    },
    createWebRegister() {
      if (
        this.formRegister.FullName.length == 0 ||
        this.formRegister.PhoneNumber.length == 0 ||
        this.disciption.length == 0 ||
        this.formRegister.Note.length == 0
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.PhoneNumber[0] != 0 ||
        this.formRegister.PhoneNumber.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      this.loadding = true;
      this.formRegister.Note = this.formRegister.Note + ' (bacsytructuyen.vn)'
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegister.PhoneNumber,
          ProductID: "bacsytructuyen.vn",
          TypeDoc: "DK",
          FullName: this.formRegister.FullName,
          Note: this.formRegister.Note,
          Desctiption: this.disciption,
          LocationRegister: 2
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.isShowBuyModal = false;
            this.isShowSuccess = false;
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
  },
  created() {
    this.getPlaceByProduct();
  },
};
</script>

<style lang = "css" scoped>
.mt-2 {
  text-align: center !important;
}
.btn-summit {
  text-align: center;
  font-family: "Quicksand Regular" !important;
  font-size: 17px !important;
  padding: 10px !important;
}
.db-table .db-col {
  width: 100%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding-left: 20px;
}
.db-table .db-col .row-box {
  max-width: 250px;
  display: block;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  background-color: #fff;
  margin-bottom: 20px;
  text-align: start;
  margin-left: 5px;
}
.db-table .db-col .row-box p {
  font-size: 14px;
  text-decoration: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 0;
  text-transform: none;
  font-weight: 100;
}
.db-table .db-col .row-box:hover {
  text-decoration: none;
  background-color: #151e5d;
  color: white;
  font-weight: 400;
  font-size: 17px !important;
  padding-top: 0 !important;
  padding-bottom: 0;
}
.db-areaname {
  border-bottom: 1px solid #151e5d;
  margin-left: 40px;
  font-size: 25px;
  color: #151e5d;
}
.db-areaname p {
  font-family: "Quicksand Bold";
}
.db-cach-sale {
  margin-bottom: 40px;
  font-family: "Quicksand Bold";
  font-size: 20px;
}
.db-cach-sale span {
  background: #151e5d;
  color: white;
  border: solid 1px #151e5d;
  border-radius: 15px;
  text-transform: uppercase;
  font-family: "Quicksand Black";
  font-size: 18px;
  padding: 10px 30px 10px 10px;
  margin-right: 15px;
}
@media (max-width: 770px) {
  .db-table .db-col {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  
}
@media (min-width: 770px) {
  .itemNumber{
    width: 30%;
  }
  .itemSolusion{
    width: 70%;
  }
}
@media (max-width: 550px) {
  .db-cach-sale span {
    float: left;
    margin-right: 5px;
    font-size: 12px;
  }
  .db-cach-sale {
    font-size: 14px;
  }
  .db-table .db-col .row-box {
    max-width: 90%;
  }
}
</style>