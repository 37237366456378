<template>
  <div
    class="container-scroll"
    style="
      position: fixed;
      width: 100%;
      height: 120vh;
      z-index: 100;
      overflow-y: auto;
    "
    v-if="isShowSuccess"
  >
    <div>
      <div
        style="
          background-color: black;
          opacity: 0.3;
          width: 100%;
          height: 2000px;
          position: absolute;
          margin-top: -200px;
        "
        @click="btClose"
      ></div>
      <div class="modal-container-local">
        <div class="modal-content-local">
          <div class="modal-content-header">
            <span class="close-local" @click="btClose">×</span>
            <p class="modal-title-local">
              Điểm bán tại {{ dataDistrict.district }}
            </p>
          </div>
          <div class="modal-body-local">
            Bạn có thể mua trực tiếp sản phẩm SIX.am tại:
            <table>
              <thead>
                <tr>
                  <th scope="col" class="test">STT</th>
                  <th scope="col">TÊN NHÀ THUỐC</th>
                  <th scope="col" class="test">ĐỊA CHỈ</th>
                  <th scope="col" class="test">QUẬN/HUYỆN</th>
                  <th scope="col" class="phone">ĐỊA CHỈ</th>
                  <th scope="col">ĐIỆN THOẠI</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in dataShopLst" :key="index">
                  <td class="test">{{ index + 1 }}</td>
                  <td>{{ item.PlaceName }}</td>

                  <td class="test">{{ item.Address }}</td>
                  <td class="test">{{ item.District }}</td>
                  <td class="phone">{{ item.Address }}, {{ item.District }}</td>
                  <td>
                    <a href="'tel:' + item.PhoneNumber">{{
                      item.PhoneNumber
                    }}</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer-local">
            <button @click="btClose">Đóng</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataDistrict: Object,
    dataShopLst: Array,
  },
  data() {
    return {
      isShowSuccess: true,
      dataModallocalLst: [
        {
          stt: "1",
          shop: "Nhà thuốc Tý Hương",
          addess: "Tầng 1 Nhà 3 chợ Hà Đông",
          district: "Hà nội",
          sdt: "0397613784",
        },
        {
          stt: "1",
          shop: "Nhà thuốc Tuấn Hà",
          addess: "Hà nội",
          district: "Hà nội",
          sdt: "0397613784",
        },
        {
          stt: "1",
          shop: "Quầy thuốc số 9",
          addess: "Hà nội",
          district: "Hà nội",
          sdt: "0397613784",
        },
      ],
    };
  },
  methods: {
    btClose() {
      this.$emit("btClose", false);
    },
  },
};
</script>

<style lang="css" scoped>
.modal-content-local {
  width: 1000px;
  position: absolute;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-clip: padding-box;
  right: 0;
  left: 0;
  margin: auto;
}
.modal-content-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}
.modal-content-local {
  border-bottom: 1px solid #e5e5e5;
}
.modal-title-local {
  font-family: "Quicksand Black";
  font-size: 20px;
  margin-bottom: 0;
}
.close-local {
  background-color: white;
  color: red;
  opacity: 1;
  font-size: 35px;
  position: absolute;
  right: 0;
  margin-right: 10px;
  top: 0;
  cursor: pointer;
}
.modal-body-local {
  position: relative;
  padding: 15px;
}
table {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  border: 0.1rem solid #dcd7ca;
}
.modal-body-local th {
  color: #151e5d;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  font-family: "Quicksand Medium";
  border: 0.1rem solid #dcd7ca;
  line-height: 1.4;
}
.modal-body-local td {
  padding: 5px 0;
  border: 0.1rem solid #dcd7ca;
  line-height: 1.4;
  font-family: "Quicksand Medium";
}
tbody tr:nth-child(2n + 1) {
  background-color: #f2f2f2;
}
.modal-body-local a {
  color: #337ab7;
}
.modal-footer-local {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer-local button {
  padding: 6px 12px;
  color: #000000;
  border: #ccc solid 1px;
  border-radius: 3px;
  background-image: linear-gradient(to bottom, #fff 0%, #e0e0e0 100%);
}
.modal-footer-local button:hover {
  text-decoration: none;
}
@media (max-width: 1023px) {
  .modal-content-local {
    width: 80%;
  }
}
@media (max-width: 800px) {
  .modal-content-local {
    width: 97%;
  }
}
@media (max-width: 750px) {
  .container-scroll {
    overflow-y: scroll;
    margin-top: -200px;
  }
  .modal-container-local {
    margin-top: 200px;
  }
}
@media (max-width: 650px) {
  .modal-content-local {
    width: 100%;
  }
  .test{
    display: none
  }
}
@media (min-width: 650px) {

.phone{
  display: none
}
}
@media (max-width: 430px) {
  .modal-content-local {
  }
}
</style>
