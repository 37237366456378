<template>
  <div>
    <div id="items-direction" style="position: absolute; top: 0"></div>
    <div id="baomoi-content" class="baomoi-content bm-home">
      <img
        style="margin: 0 auto"
        class="bm-banner-img hidden-md hidden-lg"
        src="../../../assets/images/nsut.jpg"
      />
      <img
        style="margin: 0 auto"
        class="bm-banner-img hidden-sm hidden-xs"
        src="../../../assets/images/nsut-d.jpg"
      />

      <div class="container">
        <div class="row">
          <div class="blogs-page col-xs-12">
            <p class="blogs-heading">Kiến thức về mất ngủ</p>
            <div
              class="blog-single"
              v-for="(item, index) in paginated"
              :key="index"
            >
              <img :src="item.LinkImage" alt="" @click="btnPushInfor(item)" />
              <p class="blog-title" @click="btnPushInfor(item)">
                {{ item.WebTitle }}
              </p>
              <p class="blog-Category">Chuyên mục: {{ item.Category }}</p>
              <!-- <p class="blog-des" v-html="item.Description"></p> -->
              <a
                class="blog-readmore"
                style="cursor: pointer"
                @click="btnPushInfor(item)"
                >Xem thêm <i class="fa-solid fa-circle-arrow-right"></i
              ></a>
            </div>
            <div class="clearfix"></div>
          </div>
          <b-pagination
            class="mt-5 pagination-custom"
            v-model="current"
            :total-rows="ex1Rows"
            :per-page="ex1PerPage"
            first-number
            last-number
          
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GetWebPostsLst } from "@/api/place";
import { mapMutations } from "vuex";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      current: 1,
      ex1CurrentPage: 10,
      pageSize: 10,
      ex1Rows: 7,
      ex1PerPage: 1,
      tableData: [],
    };
  },
  computed: {
    indexStart() {
      return (this.current - 1) * this.pageSize;
    },
    indexEnd() {
      return this.indexStart + this.pageSize;
    },
    paginated() {
      return this.tableData.slice(this.indexStart, this.indexEnd);
    },
  },
  methods: {
    // ...mapMutations(["updatePostID"]),
    btnPushInfor(item) {
      //this.updatePostID(item.RowID);
      Cookies.set("value", item.RowID);
      function convertWebTitlle(str) {
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        str = str.replace(
          /!|@|%|\^|\*|\(|\)|\+|\?|\/|,|\.|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
          " "
        );
        str = str.replace(/ /g, "-");
        return str;
      }
      this.titleWeb = convertWebTitlle(item.WebTitle);
      this.$router.push(this.titleWeb);
    },
    getWebPostsLst() {
      GetWebPostsLst({ ProductID: "bacsytructuyen.vn" }).then((res) => {
        this.tableData = res.data.WebPostsLst.filter((p) => p.Status == 1).map(
          (item, index) => {
            return {
              ...item,
              LinkImage:
                "https://icpc1hn.work/DTP/File/DownloadImageWebPosts?UserName=&Token=&RowID=" +
                item.RowID,
              Key: index + 1,
            };
          }
        );
        this.ex1Rows = this.tableData.length / 10 + 1;
        this.tableData = this.tableData.reverse();
      });
    },
  },
  created() {
    this.getWebPostsLst();
  },
};
</script>

<style lang = "css" scoped>
.blog-thumb {
  background-image: url("../../../assets/images/post-thumb.jpg") !important;
}
.mt-5 {
  text-align: center;
  display: block;
}
.mt-5 span {
  float: center;
}
.blog-single img {
  width: 100%;
  height: 250px;
  background: center center;
  margin-bottom: 15px;
}

.blog-Category {
  font-size: 15px;
  font-family: "Mulish Bold";
  margin-top: -5px;
  margin-bottom: 5px;
}
.blog-single {
  width: 50%;
  display: inline-block;
  padding: 0 10px;
}
.blog-des > p {
  display: none;
}
.blog-single p {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* số dòng hiển thị */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-title {
  font-family: "Mulish Bold";
  font-size: 17px;
  color: black;
}
.mt-5 > li > button {
  background-color: #122b4d !important;
}
@media (max-width: 650px) {
  .blog-single {
    width: 100%;
  }
}
</style>
<style lang="css">
.pagination-custom > li > button {
  background-color: #fff;
  padding: 7px 10px;
  font-family: "Mulish";
  color: #122B4D;
  border: #5f626640 solid 1px;
  font-weight: 600;
}
.active > button {
  background-color: #122B4D !important ;
  color: rgb(225, 204, 79) !important;
}

.pagination-custom > li {
  margin: 0;
}
.pagination-custom > li > span {
  float: none;
}
.pagination-custom .disabled > span {
  padding: 10.5px 10px 9.5px 10px !important;
}
</style>
<style>
.social_pin {
  display: none;
}

</style>