<template>
  <div>
    <headerweb :isButton="button" />
    <banner class="banner-app-six"/>
    <ordersixam />

    <direction />
    <router-view></router-view>
    <!-- @clickButton="clickButton" -->
  </div>
</template>

<script>
import headerweb from "./views/Home/components/Header-Sixam.vue";
import banner from "./views/Home/components/Banner-Sixam.vue";
import direction from "./views/Home/components/Direction-Sixam.vue";
import ordersixam from "./views/Home/components/Order-Sixam.vue";

export default {
  name: "App",
  components: {
    headerweb,
    banner,
    direction,
    ordersixam,
  },
  data() {
    return {
      windowTop: 0,
      button: "",
    };
  },
  methods: {
    onScroll(e) {
      var mybutton = document.getElementById("to-the-top");

      if (
        e.target.body.scrollTop > 20 ||
        e.target.documentElement.scrollTop > 20
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    },
    clickButton(val) {
      this.button = val;

      const btnsnav = document.getElementsByClassName("items_li");
      if (this.button == " ") btnsnav[0].style.color = "red";
    },
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
  },
  // beforeDestroy() {
  //   window.removeEventListener("scroll", this.onScroll, true)
  // },
  created() {
    this.clickButton();
  },
};
</script>

<style>
@import "~@/assets/css/lp6am.css";
@import "~@/assets/css/style-default.css";
@import "~@/assets/css/less/fonts.less";
@import "~@/assets/bootstrap/css/bootstrap.css";
@import "~@/assets/css/less/header.less";
@import "~@/assets/css/less/homepage.less";
@import "~@/assets/css/less/single.less";
@import "~@/assets/css/style-default.css";
@import "~@/assets/css/less/baomoi.less";
.banner-app-six{
  margin-top: 110px;
}
@media (max-width: 1025px){
  .banner-app-six{
  margin-top: 80px;
}}
@media (max-width: 995px){
  .banner-app-six{
  margin-top: 100px;
}
}
@media (max-width: 767px){
  .banner-app-six{
  margin-top: 90px;
}
}
</style>
