<template>
  <div>
    <div class="under-menu w-100 hidden-md hidden-lg">
      <div class="col-xs-6">
        <span style="font-size: 12px">Số: 00850/2019/ATTP-XNQC</span><br />
        Số ĐKSP: 1708/2019/ĐKSP
      </div>
      <div class="col-xs-6 menu-buy" style="cursor: pointer">
        <a class="open-buy open-buy-modal">
          <span>
            <i class="fas fa-shopping-cart"></i>
          </span>
          <span @click="isShowBuyModal = true"> Đặt hàng ngay </span>
        </a>
      </div>
    </div>
    <div class="container-buymodal" v-if="isShowBuyModal">
      <!-- Modal content -->
      <div class="bg-buy"></div>
      <div class="content-buymodal" >
        <a class="close-buymomdal" @click="isShowBuyModal = false">&times;</a>
        <div class="buy-left-img">
          <img src="../../../assets/images/hop6am.png" />
        </div>
        <div class="buy-right-img">
          <p class="buy-lead-buymodal">MẤT NGỦ - SIX.AM LÀ ĐỦ!</p>
          <ul>
            <li>
              Liệu trình dùng thử (10 ngày):
              <span>1 hộp SIX.am có giá 300.000 đồng + phí ship </span>
            </li>
            <li>
              Liệu trình 1 tháng:
              <span>2 hộp SIX.am có giá 600.000 đồng (miễn phí ship)</span>
            </li>
            <li>
              Liệu trình 2 tháng:
              <span>4 hộp SIX.am có giá 1.120.000 đồng (miễn phí ship)</span>
            </li>
          </ul>
          <div class="modal-muangay">
            <form>
              <div class="form-name">
                <span class=""
                  ><input
                    type="text"
                    name="name"
                    value=""
                    size="40"
                    class="input-name"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Họ và tên"
                    v-model="formRegister.FullName"
                /></span>
              </div>
              <div class="form-number">
                <span class=""
                  ><input
                    type="number"
                    name="number"
                    value=""
                    class="input-number"
                    min="99999999"
                    max="9999999999"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Số điện thoại"
                    v-model="formRegister.PhoneNumber"
                /></span>
              </div>
              <div class="">
                <span class="form-course"
                  ><select
                    name="course"
                    class="input-course"
                    aria-required="true"
                    aria-invalid="false"
                     @change="onChange($event)"
                   v-model="selecteded"
                  >
                    <option value="" disabled selected>Chọn liệu trình</option>
                    <option value="Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 300.000 đồng + phí ship">
                      Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 300.000 đồng + phí ship
                    </option>
                    <option value="Liệu trình 1 tháng: 2 hộp SIX.am có giá 600.000 đồng (miễn phí ship)">
                      Liệu trình 1 tháng: 2 hộp SIX.am có giá 600.000 đồng (miễn phí ship)
                    </option>
                    <option value=" Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.120.000 đồng (miễn phí ship)">
                      Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.120.000 đồng (miễn phí ship)
                    </option>
                  </select></span
                >
              </div>
              <div class="">
                <span class="form-address">
                  <textarea
                    name="address"
                    cols="40"
                    rows="10"
                    class="input-address"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Địa chỉ nhận hàng"
                    v-model="formRegister.Note"
                  ></textarea>
                </span>
              </div>
              <p
                v-if="isShowWarning"
                class="mt-2"
                style="color: #fff; margin-bottom: 16px"
              >
                {{ contentWarning }}
              </p>
              <p
                v-if="isShowSuccess"
                class="mt-2"
                style="color: #fff; margin-bottom: 16px"
              >
                {{ contentSuccess }}
              </p>
              <div @click="createWebRegister" class="btn-summit">
                <span role="status" aria-hidden="true" v-if="loadding"></span>
                Đặt hàng
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CreateWebRegister } from "@/api/place";
export default {
  data() {
    return {
      isShowBuyModal: false,
      isShowWarning: false,
      isShowSuccess: false,
      contentSuccess : "Đăng kí thành công, nhân viên CSKH sẽ sớm liên hệ với quý khách!",
      contentWarning: "Vui lòng điền đủ thông tin!",
      loadding: false,
       selecteded:'',
      disciption:'',
      formRegister: {
        PhoneNumber: "",
        FullName: "",
        Desctiption: "",
        Note: "",
      },
      
    };
  },
  methods: {
     onChange(event) {
            this.disciption = event.target.value;
        },
    createWebRegister() {
      if (
        this.formRegister.FullName.length == 0 ||
        this.formRegister.PhoneNumber.length == 0 ||
        this.disciption.length == 0 ||
        this.formRegister.Note.length == 0
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.PhoneNumber[0] != 0 ||
        this.formRegister.PhoneNumber.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      this.loadding = true;
      this.formRegister.Note = this.formRegister.Note + ' (bacsytructuyen.vn)'
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegister.PhoneNumber,
          ProductID: "bacsytructuyen.vn",
          TypeDoc: "DK",
          FullName: this.formRegister.FullName,
          Note: this.formRegister.Note,
          Desctiption: this.disciption,
          LocationRegister: 2
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.isShowBuyModal = false;
            this.isShowSuccess = false;
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
   
  },
};
</script>

<style>
.container-buymodal {
  position: fixed;
  z-index: 100;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  right: 0;
  overflow: auto;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.4);
  float: left;
  text-align: center;
}
.bg-buy
{
  position: fixed;
    z-index: -1!important;
    padding-top: 150px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,.4);
}
.content-buymodal {
  border: solid 3px #ecdf42;
  background: #122b4d;
  width: 80%;
  border-radius: 6px;
  display: inline-block;
}
.close-buymomdal {
  color: white;
  float: right;
  font-size: 29px;
  font-weight: bold;
  opacity: 1;
  margin-top: 15px;
  margin-right: 20px;
  cursor: pointer;
}
.close-buymomdal:hover {
  color: red;
}
.buy-left-img {
  float: left;
  width: 35%;
  margin-top: 20px;
}
.buy-left-img img {
  display: block;
  max-width: 420px;
  margin: 30px auto 0;
  width: 100%;
}
.buy-right-img {
  float: left;
  width: 63.5%;
}
.buy-lead-buymodal {
  color: #ecdf42;
  font-family: "Mulish Black";
  font-size: 20px;
  margin-bottom: 0;
}
.buy-right-img ul {
  color: white;
  font-family: Mulish;
  font-size: 15px;
  margin-left: 10px;
  line-height: 1.2;
  margin-bottom: 15px;
}
.buy-right-img ul li {
  margin-left: 3px;
  font-family: "Mulish Bold";
  text-align: start;
}
.buy-right-img ul li span {
  color: #ecdf42;
}
.form-name {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.input-name {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
.form-number {
  padding-right: 10px;
  float: left;
  width: 19%;
  margin-bottom: 15px;
}
.input-number {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
.form-course {
  float: left;
  width: 81%;
  margin-bottom: 10px;
}
.input-course {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
.input-course option:hover {
  box-shadow: 0 0 10px 100px #000 inset;
}
.form-address {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.input-address {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
.form-submit {
  width: 100%;
  float: left;
  margin-bottom: 35px;
}
.btn-buydialog {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
@media (max-width: 1350px) {
  .content-buymodal {
    width: 90%;
  }
  .close-buymomdal {
    margin-right: 10px;
  }
}
@media (max-width: 950px) {
  .buy-left-img {
    float: center;
    display: inline-block;
    width: 100% !important;
    text-align: center;
    margin-top: 0px;
  }
  .buy-left-img img {
    display: block;
    max-width: 290px !important;
    margin: 30px auto 0;
    width: 100%;
  }
  .buy-right-img {
    width: 100%;
  }
  .buy-right-img ul {
    margin: 0 0 1.5rem 3rem;
  }
  .modal-muangay {
    padding-left: 10px;
    padding-right: 10px;
  }
  .input-address {
    height: 40px;
  }
  .container-buymodal {
    padding-top: 100px;
  }
}
@media (max-width: 600px) {
  .container-buymodal {
    padding-top: 70px;
  }
  .content-buymodal {
    width: 100%;
  }
  .buy-right-img ul li {
    width: 95%;
  }
}
@media (max-width: 420px) {
  .container-buymodal {
    padding-top: 50px;
  }
  .buy-right-img ul {
    margin: 0;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  .buy-left-img img {
    margin: 0;
    display: inline-block;
  }
  .modal-muangay {
    padding-left: 10px;
    padding-right: 10px;
  }
  .form-name {
    margin-bottom: 4px;
  }
  .form-number {
    width: 100%;
    padding-right: 0;
    margin-top: 0 !important;
    margin-bottom: 4px;
  }
  .form-course {
    width: 100%;
    padding-right: 0;
    margin-top: 0 !important;
    margin-bottom: 4px;
  }
  .input-course {
    margin-bottom: 0;
  }
  .form-address {
    margin-bottom: 0;
  }
  .form-submit {
    margin-bottom: 20px;
  }
}
</style>